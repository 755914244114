import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'
import Hotjar from '@hotjar/browser'
import reportWebVitals from './reportWebVitals'
import 'font.css'
import { Provider } from 'react-redux'
import getStore from 'store'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'react-loading-skeleton/dist/skeleton.css'
import { IntercomProvider } from 'react-use-intercom'
import {
  ChakraBaseProvider,
  // extendBaseTheme,
  // theme,
} from '@chakra-ui/react'

if (process.env.NODE_ENV === 'production') {
  //initialize sentry
  Sentry.init({
    dsn: 'https://7711b12864cb4977a663c41a713305a0@o4504663410475008.ingest.sentry.io/4504949136162816',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: 'production',
  })

  // Initialize HotJar

  const siteId = 3599200
  const hotjarVersion = 6

  Hotjar.init(siteId, hotjarVersion)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
export let persistor = persistStore(getStore())
const queryClient = new QueryClient()
const INTERCOM_APP_ID = 'os63hpix'


// const theme = extendBaseTheme({

// })

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <BrowserRouter>
          <Provider store={getStore()}>
            <PersistGate persistor={persistor}>
              <ChakraBaseProvider>
                <App />
              </ChakraBaseProvider>
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </IntercomProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
